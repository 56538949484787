@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../../theme/utils/Mixins.module.scss';
@import '../../../theme/utils/Variables.module.scss';
@import '../../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.socialSection {
	padding: 30px 0;
	margin: 0;
	.myContainer {
		@media only screen and (max-width: 767px) {
			padding: 0 15px;
		}
		.instagramFeed {
			max-width: 1060px;
			margin: 0 auto;
			.sectionHead {
				margin-bottom: 0;
				@media only screen and (max-width: 767px) {
					font-size: 22px;
				}
			}
			.subHead {
				font-size: 17px;
				color: var(--primary3);
				font-family: 'Futura PT';
				font-weight: 300;
				text-align: center;
				display: block;
				text-transform: uppercase;
				margin-bottom: 20px;
				.handler {
					color: var(--primary3);
					text-decoration: none;
					text-transform: uppercase;
					cursor: pointer;
				}
			}
			.lightWidget {
				width: 100%;
				border: 0px;
				overflow: hidden;
				height: 353px;
			}
		}
	}
}
.instaFeed {
	&::-webkit-scrollbar {
		width: 1em;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}
}
