@font-face {
    font-family: "Cormorant Garamond";
    src: url('./Cormorant_Garamond/CormorantGaramond-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Cormorant Garamond";
    src:  url('./Cormorant_Garamond/CormorantGaramond-Light.woff2') format('woff2');
    font-weight: lighter;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Cormorant Garamond";
    src:  url('./Cormorant_Garamond/CormorantGaramond-SemiBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

@font-face {
    font-family: "Futura PT";
    src: url('./futura/FuturaPT-Book.woff2') format('woff2');
}

/* @font-face {
    font-family: "Futura PT Light";
    src: url('./futura/Futura\ Light\ font.ttf') format('truetype');
} */