@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../theme/utils/Mixins.module.scss';
@import '../../theme/utils/Variables.module.scss';
@import '../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.mainHeader,
.mainMobHeader {
	// background: var(--primary1);
	z-index: 9999;
	top: 0;
	width: 100%;
	transition: 0.5s all;
	position: fixed;
	display: block;
}
.darkBackground {
	background: var(--primary1);
}
.transBackground {
	background: transparent;
}

// Mobile header
.mainMobHeader {
	@media only screen and (min-width: 1025px) {
		display: none;
	}
	&.mainHeaderScrolled {
		background: var(--primary1);
	}
	.header_top {
		padding: 3.5px 3vw;
		text-align: center;
		background: white;
		.header_top_msg {
			p {
				margin: 0;
				text-align: center;
				font-size: 14px;
				color: black;
				font-family: 'Futura PT';
			}
		}
	}
	.header_bottom {
		position: relative;
		.myContainer {
			width: 100%;
			padding: 0 10px;
			margin: 0 auto;
			.headerWrapper {
				@include displayFlex(row, space-between, center);
				height: 40px;
				transition: 0.5s all;
				&.headerWrapperScrolled {
					height: 40px;
				}
				.navSection,
				.logoBg,
				.headerActions {
					width: calc(100% / 3);
				}
				.navSection {
					@include displayFlex(row, flex-start, center);
					margin: 0;
					background: 0 0;
					.navSectionItems {
						@include displayFlex(row, center, center);
						.mobileSunCrossWrap {
							display: block;
							.sunLogo {
								display: inline-block;
								filter: brightness(0) invert(1);
								@include setWidthHeight(50px, auto);
								.hamburger {
									font-size: 28px;
									font-weight: 400;
									color: #ffffff;
								}
							}
						}
						.navigationCustom {
							background: 0 0;
							font-weight: 400;
							position: static;
							.navigationCustomUL {
								padding: 0;
								position: static;
								@include displayFlex(row, center, center);
								list-style-type: none;
								margin-bottom: 0;
								.categoryItem {
									.linkItem {
										text-decoration: none;
										color: white;
										line-height: initial;
										font-family: 'Cormorant Garamond';
										font-size: 16px;
										font-weight: 400;
										font-style: normal;
										text-transform: uppercase;
										letter-spacing: 2px;
										padding: 0 10px;
										z-index: 999;
										transition: 0.5s all;
										position: relative;
										&:hover {
											color: var(--primary2);
										}

										&.active {
											&:after {
												content: '';
												width: calc(100% - 20px);
												display: block;
												height: 1px;
												background: #fff;
												position: absolute;
												bottom: -2px;
												left: 50%;
												transform: translateX(-50%);
											}
										}
									}
									&:hover {
										.megaDropDownMenu {
											opacity: 1;
											visibility: visible;
										}
									}
								}
							}
						}
					}
				}
				// Logo
				.logoBg {
					max-width: 130px;
					display: flex;
					justify-content: center;
					transform: scale(1);
					transition: 0.5s all;
					&.logoBgScrolled {
						transform: scale(1);
					}
					.linkItem {
						.logoMain {
							width: 100%;
							display: block;
							filter: brightness(0) invert(1);
						}
					}
				}
				// Right-side action buttons
				.headerActions {
					@include displayFlex(row, flex-end, center);
					padding-right: 0;
					position: relative;

					.searchBlock {
						padding: 0 0 0 15px;
						margin-right: -15px;
						float: right;
						position: relative;
						width: 250px;
						.inputArea {
							font-family: 'Roboto Mono';
							font-size: 12px;
							color: #fff;
							border: none;
							border-bottom: 1px solid #fff;
							background: 0 0;
							margin: 0;
							padding-right: 35px;
							position: static;
							width: 100%;
							@include noOutlineOnAction;
						}
					}
					.headerActionIcons {
						.iconList {
							@include displayFlex(row, flex-end, center);
							margin-bottom: 0;
							padding: 0;
							.iconListItem {
								display: block;
								position: relative;
								list-style: none;
								&:not(:last-child) {
									margin-right: 5px;
								}

								.actionLink {
									position: relative;
									text-decoration: none;
									@include displayFlex(row, center, center);
									@include setWidthHeight(18px, 18px);
									cursor: pointer;
									.actionImg {
										filter: brightness(0) invert(1);
										@include setWidthHeight(100%, 100%);
									}
									.actionImg2 {
										@include setWidthHeight(100%, 100%);
									}
								}

								.myLanguageDropdown {
									.actionImg2 {
										margin-right: 0px;
										@include setWidthHeight(30px, auto);
									}
									.myDropdownBtn {
										background: transparent;
										border-color: transparent;
										@include displayFlex(row, center, center);
										color: white;
										line-height: initial;
										font-family: 'Cormorant Garamond';
										font-size: 16px;
										font-weight: 400;
										font-style: normal;
										text-transform: uppercase;
										letter-spacing: 2px;
										padding: 0 10 0 0px;
										@include noOutlineOnAction;
									}
									.myDropdownMenu {
										overflow: hidden;
										transition: 1s all;
										top: 100%;
										right: 0;
										min-width: 7rem;
										.dropdownItem {
											color: inherit;
											padding: 0.2rem 0.5rem;
											font-family: 'Cormorant Garamond';
											font-size: 16px;
											font-weight: bold;
											font-style: normal;
											text-transform: uppercase;
											@include displayFlex(row, space-between, center);
										}
									}
									&:hover {
										.myDropdownMenu {
											display: block;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.headerDrawer {
		position: fixed;
		top: 0;
		left: 0;
		transition: left 0.3s;
		width: 320px;
		background: #fff;
		height: 100%;
		overflow: hidden scroll;
		z-index: 99999;
		display: block;
		.myContainer {
			padding-left: 0px;
			padding-right: 0px;
		}
		.drawerContent {
			position: relative;
			.closeBtn {
				position: absolute;
				right: 10px;
				top: 10px;
				cursor: pointer;
			}
			.imgBox,
			.menuBar {
				padding: 10px 10px;
			}
			.imgBox {
				padding: 10px inherit;
				border-bottom: 1px solid #ccc;
				.sunLogo {
					width: 50px;
				}
			}
			.searchBlock {
				padding: 0 10px;
				position: relative;
				margin: 10px auto;
				width: 100%;
				.inputArea {
					font-family: 'Roboto Mono';
					font-size: 12px;
					color: #4d3535;
					border-bottom: 1px solid #4d3535;
					border-top: none;
					border-left: none;
					border-right: none;
					background: 0 0;
					margin: 0;
					padding-right: 35px;
					position: relative;
					width: 100%;
					@include noOutlineOnAction;
					&::placeholder {
						color: #4d3535;
						font-family: 'Futura PT';
						font-size: 11px;
					}
				}
				.searchIcon {
					position: absolute;
					top: 0;
					right: 10px;
				}

				.searchAutoComplete {
					// display: none;
					display: block;
					position: absolute;
					top: 25px;
					transform: translateY(0);
					width: 240px;
					z-index: 3;
					padding: 10px;
					background: #fff;
					box-shadow: 0px 0px 5px 1px #ccc;
					@media (min-width: 768px) {
						margin-top: 0;
					}
					.searchList {
						background: #fff;
						margin-top: 10px;
						margin: 0;
						padding: 0;
						list-style: none;
						.searchListItem {
							font-family: 'Futura PT';
							background: 0 0;
							font-size: 16px;
							letter-spacing: 1px;
							border: 0;
							color: var(--primary1);
							margin-bottom: 8px;
							cursor: pointer;
							margin: 0;
							padding: 5px 40px 5px 10px;
							position: relative;
							text-align: left;
							white-space: normal;
							text-decoration: none;
							.searchListItemLink {
								color: var(--primary1);
								text-decoration: none;
							}
						}
					}
				}
			}
			.menuBar {
				.menuItems {
					.menuList,
					.subItemsList {
						list-style: none none;
						padding: 0 5px;
					}
					.menuList {
						// li {
						// 	font-family: 'Cormorant Garamond';
						// 	font-size: 16px;
						// 	font-weight: 400;
						// 	font-style: normal;
						// 	text-transform: uppercase;
						// 	width: 100%;
						// 	color: var(--primary1);
						// 	text-decoration: none;
						// 	@include displayFlex(row, space-between, center);
						// }
						// .subMenuListItem {
						// 	font-family: 'Cormorant Garamond';
						// 	font-size: 16px;
						// 	font-weight: 400;
						// 	font-style: normal;
						// 	text-transform: uppercase;
						// 	width: 100%;
						// 	color: var(--primary1);
						// 	text-decoration: none;
						// 	@include displayFlex(row, space-between, center);
						// }
						text-decoration: none;
						padding: 0;
						text-transform: uppercase;
						.menuListItem {
							.menuListItemLink,
							.subMenuListItemLink,
							.subMenuListItem {
								font-family: 'Cormorant Garamond';
								font-size: 16px;
								font-weight: 400;
								font-style: normal;
								text-transform: uppercase;
								width: 100%;
								color: var(--primary1);
								text-decoration: none;
								@include displayFlex(row, space-between, center);
							}
							.menuListItemLink {
								padding: 10px 0;
							}
							.subMenuListItem {
								padding: 5px 0 !important;
							}
						}
					}
				}
			}
		}
	}
	.greetingMsgBox {
		display: flex !important;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.greetingMsg {
			font-family: 'Futura PT';
			font-size: 12px;
			text-align: right;
			color: var(--primary1);
		}
	}
}

// Web Header
.mainHeader {
	@media only screen and (max-width: 1024px) {
		display: none;
	}
	&:hover {
		background: var(--primary1);
	}
	&.mainHeaderScrolled {
		background: var(--primary1);
	}

	.header_top {
		padding: 3.5px 3vw;
		text-align: center;
		background: white;
		.header_top_msg {
			p {
				margin: 0;
				text-align: center;
				font-size: 14px;
				color: black;
				font-family: 'Futura PT';
			}
		}
	}
	.header_bottom {
		position: relative;
		.myContainer {
			width: 100%;
			padding: 0 30px;
			margin: 0 auto;
			.headerWrapper {
				@include displayFlex(row, space-between, center);
				height: 110px;
				transition: 0.5s all;
				&.headerWrapperScrolled {
					height: 65px;
				}
				// Left-side Menus
				.navSection {
					width: 42%;
					@include displayFlex(row, flex-start, center);
					margin: 0;
					background: 0 0;
					&.navSectionScrolled {
						width: 70%;
						justify-content: center;
					}
					.navSectionItems {
						@include displayFlex(row, center, center);
						&.navSectionItemsScrolled {
							justify-content: space-between;
							width: 100%;
						}
						.mobileSunCrossWrap {
							display: block;
							.sunLogo {
								display: inline-block;
								filter: brightness(0) invert(1);
								@include setWidthHeight(50px, auto);
								.sunLogoImg {
									@include setWidthHeight(100%, 100%);
								}
							}
						}
					}
				}
				// Logo
				.middleContainer {
					padding-bottom: 15px;
					&.middleContainerScrolled {
						padding-bottom: 0;
					}
				}
				.logoBg {
					width: 100%;
					max-width: 210px;
					display: flex;
					justify-content: center;
					transform: scale(1);
					transition: 0.5s all;
					margin: 20px auto 10px;
					&.logoBgScrolled {
						// transform: scale(0.8);
						display: none;
					}
					.linkItem {
						.logoMain {
							width: 100%;
							display: block;
							filter: brightness(0) invert(1);
						}
					}
				}
				// Right-side action buttons
				.headerActions {
					@include displayFlex(row, flex-end, center);
					width: 100%;
					padding-right: 0;
					position: relative;
					&.headerActionsScrolled {
						width: 35%;
					}
					.searchBlock {
						padding: 0 0 0 15px;
						margin-right: -15px;
						float: right;
						position: relative;
						width: 250px;
						.inputArea {
							font-family: 'Roboto Mono';
							font-size: 12px;
							color: #fff;
							border: none;
							border-bottom: 1px solid #fff;
							background: 0 0;
							margin: 0;
							padding-right: 35px;
							position: static;
							width: 100%;
							@include noOutlineOnAction;
						}
						.searchAutocompleteBackdrop {
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							position: fixed;
						}
						.searchAutoComplete {
							// display: none;
							display: block;
							position: absolute;
							top: 25px;
							transform: translateY(0);
							width: 240px;
							z-index: 3;
							padding: 10px;
							background: #fff;
							box-shadow: 0px 0px 5px 1px #ccc;
							@media (min-width: 768px) {
								margin-top: 0;
							}
							.searchList {
								background: #fff;
								margin-top: 10px;
								margin: 0;
								padding: 0;
								list-style: none;
								.searchListItem {
									font-family: 'Futura PT';
									background: 0 0;
									font-size: 16px;
									letter-spacing: 1px;
									border: 0;
									color: #1f1f1f;
									margin-bottom: 8px;
									cursor: pointer;
									margin: 0;
									padding: 5px 10px;
									position: relative;
									text-align: left;
									white-space: normal;
									.searchListItemLink {
										font-family: 'Futura PT';
										background: 0 0;
										font-size: 16px;
										letter-spacing: 1px;
										border: 0;
										color: #1f1f1f;
										margin-bottom: 8px;
										cursor: pointer;
										margin: 0;
										padding: 0px;
										position: relative;
										text-align: left;
										white-space: normal;
										text-decoration: none;
									}
								}
							}
						}
					}
					.headerActionIcons {
						.iconList {
							@include displayFlex(row, space-between, center);
							margin-bottom: 0;
							padding: 0;
							.iconListItem {
								display: block;
								position: relative;
								list-style: none;
								&:not(:last-child) {
									margin-right: 20px;
								}
								.actionLink {
									text-decoration: none;
									@include displayFlex(row, center, center);
									@include setWidthHeight(18px, 18px);
									cursor: pointer;
									.actionImg {
										filter: brightness(0) invert(1);
										@include setWidthHeight(100%, 100%);
									}
									.actionImg2 {
										@include setWidthHeight(100%, 100%);
									}
								}
								.accountMenuBox {
									opacity: 0;
									visibility: hidden;
									transition: 0.5s all;
									position: absolute;
									top: 42px;
									right: -45px;
									width: 200px;
									background-color: #ffffff;
									padding: 10px 20px;
									z-index: 9;
									box-shadow: 0px 0px 5px 0px;
									.accountMenuBoxItem {
										list-style: none;
										margin-bottom: 10px;
										.accountMenuBoxItemTitle {
											font-family: 'Futura PT';
											font-size: 14px;
											font-weight: 400;
											color: #1f1f1f;
											text-decoration: none;
											text-transform: capitalize;
											display: block;
											width: 100%;
										}
									}
								}
								.myLanguageDropdown {
									.actionImg2 {
										margin-right: 0px;
										@include setWidthHeight(30px, auto);
									}
									.myDropdownBtn {
										background: transparent;
										border-color: transparent;
										@include displayFlex(row, center, center);
										color: white;
										line-height: initial;
										font-family: 'Cormorant Garamond';
										font-size: 16px;
										font-weight: 400;
										font-style: normal;
										text-transform: uppercase;
										letter-spacing: 2px;
										padding: 0 10 0 0px;
										@include noOutlineOnAction;
									}
									.myDropdownMenu {
										overflow: hidden;
										transition: 1s all;
										top: 100%;
										right: 0;
										min-width: 7rem;
										.dropdownItem {
											color: inherit;
											padding: 0.2rem 0.5rem;
											font-family: 'Cormorant Garamond';
											font-size: 16px;
											font-weight: bold;
											font-style: normal;
											text-transform: uppercase;
											@include displayFlex(row, space-between, center);
										}
									}
									&:hover {
										.myDropdownMenu {
											display: block;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// Menus
.navigationCustom {
	background: 0 0;
	font-weight: 400;
	position: static;
	.navigationCustomUL {
		padding: 0;
		position: static;
		@include displayFlex(row, center, center);
		list-style-type: none;
		margin-bottom: 0;
		.categoryItem {
			.linkItem {
				text-decoration: none;
				color: white;
				line-height: initial;
				font-family: 'Cormorant Garamond';
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				text-transform: uppercase;
				letter-spacing: 2px;
				padding: 0 10px;
				z-index: 999;
				transition: 0.5s all;
				position: relative;
				&:hover {
					color: var(--primary2);
				}

				&.active {
					&:after {
						content: '';
						width: calc(100% - 20px);
						display: block;
						height: 1px;
						background: #fff;
						position: absolute;
						bottom: -2px;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
			&:hover {
				.megaDropDownMenu {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

// Mega Dropdown
.megaDropDownMenu {
	display: block;
	background: rgba(255, 255, 255, 0.95);
	// box-shadow: 2px 7px 9px 0 rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 20px 4rem;
	z-index: 1000;
	opacity: 0;
	visibility: hidden;
	transition: all, 0.3s;
	.subMenuItems {
		margin: 0;
		padding: 0;
		text-align: left;
		height: auto;
		width: 100%;
		display: table;
		list-style: none;
		.navLinkList {
			width: 50%;
			float: left;
		}
		.navLink1,
		.navLink2 {
			text-decoration: none;
			display: block;
			line-height: inherit;
			color: var(--primary1);
			font-family: 'Cormorant Garamond';
			font-weight: 400;
			font-size: 18px;
			transition: 0.5s all;
			&:hover {
				color: var(--secondary5);
			}
		}
		.navLink1 {
			padding: 0px;
		}
		.navLink2 {
			padding: 5px 0px;
		}

		&.noFeaturedImg {
			@include displayFlex(row, flex-start, center);
			.navLinkList {
				width: 25%;
			}
		}
	}
	.featuredContainer {
		@include setWidthHeight(50%, auto);
		.featuredImageCard {
			.featuredImageLink {
				text-decoration: none;
				display: block;
				line-height: inherit;
				color: var(--primary1);
				padding: 5px 20px;
				font-family: 'Cormorant Garamond';
				font-weight: 400;
				font-size: 18px;
				&:hover,
				&:active,
				&:focus {
					color: var(--primary2);
				}
				.featuredImage {
					@include setWidthHeight(100%, 100%);
				}
			}
		}
	}
}
// wishlist
.wishlistContainer {
	position: relative;
	.cartCounter {
		background: #ca9274;
		color: #fff;
		width: 18px;
		height: 18px;
		margin: 3px 0 0;
		white-space: normal;
		border-radius: 50%;
		padding: 3px;
		font-size: 11px;
		font-family: 'Roboto Mono';
		position: absolute;
		overflow: hidden;
		right: -15px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}
// Cart

.cartIcon {
	position: relative;
	.cartCounter {
		background: #1f1f1f;
		color: #fff;
		height: 18px;
		line-height: 18px;
		display: inline-block;
		margin: 3px 0 0;
		min-width: 18px;
		overflow: hidden;
		padding: 0 3px;
		text-align: center;
		white-space: normal;
		border-radius: 50%;
		width: 18px;
		font-size: 11px;
		font-family: 'Roboto Mono';
		position: absolute;
		right: -15px;
	}
}
.iconListItem:nth-of-type(4):hover .cartPopup,
.iconListItem:nth-of-type(3):hover .accountMenuBox {
	opacity: 1 !important;
	visibility: visible !important;
}
.cartPopup {
	position: absolute;
	display: block;
	opacity: 0;
	visibility: hidden;
	transition: 0.5s all;
	top: 100%;
	right: -25px;
	width: 470px;
	margin-top: 10px;
	padding: 25px 40px;
	border: 1px solid #d5aa93;
	height: auto;
	background: #fff;
	// min-width: 100%;
	z-index: 101;
	box-sizing: border-box;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	&:before {
		content: '';
		border: 9px solid;
		border-color: transparent transparent #fff;
		z-index: 104;
		position: absolute;
		right: 0;
		top: -18px;
		transform: translateX(-45px);
	}
	&:after {
		content: '';
		border: 10px solid;
		border-color: transparent transparent #d89069;
		z-index: 104;
		position: absolute;
		right: 0;
		top: -20px;
		transform: translateX(-44px);
		z-index: 4;
	}

	.cartPopupContentWrap {
		.cartHeaderContainer {
			border-bottom: 1px solid var(--primary2);
			.cartHeader {
				font-family: 'Futura PT';
				font-weight: 500;
				padding-bottom: 10px;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin: 0;
			}
		}
	}
	.emptyCart {
		display: block;
		font-size: 14px;
		padding: 0;
		text-align: center;
		font-family: 'Futura PT';
		color: #1f1f1f;
		letter-spacing: 1px;
	}
	.filledCart {
		.cartItemsWrapper {
			border: none;
			height: initial;
			max-height: 200px;
			overflow-y: scroll;
			border-bottom: 1px solid var(--primary2);
			margin: 0;
			padding: 15px 0;
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--primary2);
				outline: 1px solid var(--primary2);
			}
			.cartItemsListBox {
				margin: 0;
				padding: 0;
				list-style: none;
				.listItem {
					&:not(:first-child) {
						padding-top: 5px;
						border-top: 1px solid #ca9273;
					}
				}
				.productCard {
					@include displayFlex(row, flex-start, flex-start);
					.imgBox {
						width: 100px;
						.img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					.productDetails {
						font-family: 'Futura PT';
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 1px;
						position: static;
						padding: 0 12px;
						.productDetailsTitle {
							margin-bottom: 10px;
							text-transform: capitalize;
							font-weight: 400;
						}
						.productDetailsSubTitle {
							margin: 0;
							font-family: 'Futura PT';
							font-weight: 400;
							font-size: 13px;
							text-transform: uppercase;
							letter-spacing: 1px;
						}
						.price {
							margin-top: 10px;
							margin-bottom: 4px;
							font-family: 'Gotham-Book';
							font-size: 18px;
							font-weight: 500;
						}
					}
					.deleteIcon {
						cursor: pointer;
						padding-right: 10px;
					}
				}
			}
		}
		.subTotal {
			margin: 10px 0;
			.orderSummaryTitle {
				margin-bottom: 10px;
			}
			.mainSubTotalWrap {
				background-color: #e5e1dd;
				padding: 15px 10px 15px;
				.subTotalPrice,
				.totalPrice {
					@include displayFlex(row, space-between, center);
					margin: 0;
					font-family: 'Futura PT';
					font-size: 15px;
					text-transform: capitalize;
					letter-spacing: 1px;
					font-weight: 500;
					padding-top: 10px;
					padding-bottom: 10px;
				}
				.totalPrice {
					font-weight: 600;
				}
			}
		}
		.actionBtn {
			.checkoutBtn {
				font-family: 'Cormorant Garamond';
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 1px;
				margin: 0;
				// padding: 15px 0;
				padding: 10px 20px;
				line-height: inherit;
				background: var(--primary2);
				border: none;
				border-radius: 0;
				width: 100%;
				color: #ffffff;
			}
			.viewCartBtn {
				font-family: 'Futura PT';
				font-size: 14px;
				letter-spacing: 1px;
				border: 1px solid var(--primary2);
				padding: 7px 20px;
				color: var(--primary2);
				background: #ffffff;
				display: block;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
			}
		}
	}
}
// account userName
.account {
	display: flex;
	flex-direction: column;
	position: relative;
	.greetingMsg {
		font-family: 'Futura PT';
		font-size: 12px;
		display: inline-block;
		position: absolute;
		width: 100px;
		left: 50%;
		transform: translate(-50%, 0px);
		top: 22px;
		text-align: center;
		color: #ffffff;
		z-index: 9;
	}
}

// Confirmation Dialog
.confirmationDialog {
	display: none;
	&.show {
		@include displayFlex(row, center, center);
	}
	.modalDialog {
		max-width: 50rem;
		width: 100%;
		transition: transform 0.2s ease;
		box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
		.modalContent {
			padding: 0 2rem 0 3rem;
			.modalHeader {
				border: none;
				padding: 20px 0 0 0;
			}
			.modalBody {
				padding: 0;
				.modalBodyContent {
					padding-right: 7rem;
					font-family: 'Futura PT';
					color: #1f1f1f;
					font-size: 16px;
					letter-spacing: 1px;
				}
			}
			.modalFooter {
				margin: 0;
				padding: 20px 0;
				border: none;
				@include displayFlex(row, flex-start, center);
				.btn {
					font-family: 'Futura PT';
					font-size: 16px;
					border: none;
					background: #ca9275;
					color: #fff;
					text-transform: uppercase;
					border-radius: 0;
					font-weight: 400;
					letter-spacing: 2px;
				}
			}
		}
	}
}
