@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../../theme/utils/Mixins.module.scss';
@import '../../../theme/utils/Variables.module.scss';
@import '../../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.loginSignUpModal{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .loginSignUpForm{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: url(https://janaviindia.com/static/frontend/Digital/default/en_US/images/loginSignupBanner.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        width: 80%;
        height: calc(100vh - 10vh);
        padding: 20px;
        z-index: 999;
        @media only screen and (max-width:1024px){
            background: none;
        }
        .closePopup{
            background: url(https://janaviindia.com/static/frontend/Digital/default/en_US/images/popup-cross.png) no-repeat center/cover;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 23px;
            right: 27px;
            cursor: pointer;
            @media only screen and (max-width:1024px){
                display: none;
            }
            &.showOnMob{
                display: none;
                @media (max-width:767px){
                    display: block !important;
                }
            }
        }
        .popupLogo{
            background: url(https://janaviindia.com/static/frontend/Digital/default/en_US/images/logo.png) no-repeat center/cover;
            width: 200px;
            height: 65px;
            position: absolute;
            bottom: 23px;
            right: 27px;
            filter: brightness(0) invert(1);
            @media only screen and (max-width:1024px){
                display: none;
            }
        }
        .formPage{
            width: 380px;
            padding: 30px;
            background: rgba(0,0,0,.6);
            border: 1px solid var(--primary2);
            color: #fff;
            text-align: center;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .blockTitleContainer{
                .blockTitle{
                    text-transform: uppercase;
                    font-size: 26px;
                    font-family: "Cormorant Garamond";
                    font-weight: 400;
                    text-align: center;
                    display: block;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    color: #fff;
                }
            }
            .blockContentContainer{
                margin: 0;
                .errorMsg{
                    font-family: "Futura PT";
                    color: red;
                    font-size: 13px;
                    text-align: left;
                }
                .myForm{
                    .myFormFields{
                        margin: 5px 0;
                        width: 100%;
                    }
                    .myInputField{
                        width: 100%;
                        background: 0 0;
                        height: 40px;
                        border-radius: 0;
                        appearance: none;
                        border: 1px solid var(--primary2);
                        text-transform: lowercase;
                        font-family: "Futura PT";
                        font-weight: 400;
                        color: #fff;
                        padding: 0 9px;
                        font-size: 14px;
                        &::placeholder{
                            color: #fff;
                            font-size: 11px;
                            text-transform: uppercase;
                            font-family: "Futura PT";
                            font-weight: 300;
                        }
                        &:focus{
                            box-shadow: 0 0 3px 1px #68a8e0;
                            outline: none;
                        }
                    }
                    .actionButtons{
                        .secondary{
                            width: 100%;
                            color: #fff;
                            .forgotPwd{
                                text-decoration: none;
                                margin: 0;
                                font-family: "Futura PT";
                                font-weight: 300;
                                color: #fff;
                                font-size: 11px;
                                text-transform: lowercase;
                                display: block;
                                text-align: right;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                        .primary{
                            margin-bottom: 0;
                            width: 100%;
                            .signIn{
                                margin-top: 10px;
                                margin-bottom: 0;
                                background: var(--primary2);
                                border-radius: 0;
                                border: none;
                                text-transform: uppercase;
                                font-size: 14px;
                                font-family: "Cormorant Garamond";
                                font-weight: 400;
                                letter-spacing: 1px;
                                color: #fff;
                                width: 100%;
                                height: 40px;
                            }
                        }
                    }
                }
                .socialLogin{
                    margin: 15px 0;
                    .blockContent{
                        display: block;
                        margin: 0;
                        padding: 0;
                        .blockContentList{
                            margin: 0 10px;
                            display: inline-block;
                            .socialLoginLink{
                                display: block;
                                text-align: center;
                                width: 30px;
                                height: 30px;
                                border: 1px solid var(--primary2);
                                border-radius: 50%;
                                cursor: pointer;
                                @include displayFlex(row, center, center);
                                .fontAwesome{
                                    color: #fff;
                                    font-size: 14px;
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                }
            }
        }


        .formPage1{
            width: 500px;
            .myCustomField{
                @include displayFlex(row, center,center);
                .myFormFields{
                    &:not(:last-child){
                        margin-right: 8px !important;
                    }
                }
            }
            .subscribeNewsletter{
                padding: 9px 0;
                @include displayFlex(row, center, center);                
            }
            .termsConditions{
                padding: 9px 0;
                @include displayFlex(row, center, flex-start);
                .customCheckBoxContainer{
                    margin-top: 3px;
                }
            }
            .customCheckBoxContainer{
                position: relative;
                width: 14px;
                .checkbox{
                    display: block;
                    margin-top: 0;
                    opacity: 0;
                    z-index: 99;
                    cursor: pointer;
                    &:checked ~ .checkTick{
                        display: block;
                    }
                }
                .roundCheck{
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    position: absolute;
                    top: 0px;
                    z-index: -1;
                }
                .checkTick{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 11px;
                    color: #fff;
                    -webkit-text-stroke: 1px #fff;
                    display: none;
                    z-index: -1;
                }
                
            }
            .checkboxLabel, .termsLink{
                font-family: "Futura PT";
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 1px;
                color: #fff;
                margin-left: 5px;
                cursor: pointer;
                &:hover{
                    color: #ffffff !important;
                }
                &.subscribe{
                    text-transform: uppercase;
                }
            }
        }
    }
}

.formDivider{
    position: relative;
    text-align: center;
    margin: 15px 0;
    &:before, &:after{
        content: "";
        display: block;
        width: 40%;
        height: 1px;
        background:var(--primary2);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &:before{
        left: 0;
    }
    &:after{
        right: 0;
    }
    .dividerContent{
        font-family: "Futura Pt";
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
        padding: 0 10px;
        text-align: center;
        display: inline-block;
    }
}

.primary{
    margin-bottom: 0;
    width: 100%;
    .primaryBtn{
        margin-top: 10px;
        margin-bottom: 0;
        background: rgba(95, 67, 53, 0.8);
        border: 1px solid var(--primary2);
        border-radius: 0;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Cormorant Garamond";
        font-weight: 400;
        letter-spacing: 1px;
        color: #fff;
        width: 100%;
        height: 40px;
        cursor: pointer;
    }
}
.secondary{
    display: block;
    width: 100%;
    color: #fff;
    .secondaryBtn{
        // width: 100%;
        background: transparent;
        box-shadow: none;
        border-color: transparent;
        outline: none;
        margin-bottom: 0;
        font-family: "Gotham-Book";
        color: #ffffff;
        font-size: 12px;
        text-transform: uppercase;
        &:hover{
            text-decoration: underline;
        }
    }
}


.responseMsg{
    .errorMsg{
        font-family: "Futura PT";
        color: red;
        font-size: 13px;
        text-align: left;
    }
}