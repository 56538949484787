@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../theme/utils/Mixins.module.scss';
@import '../../theme/utils/Variables.module.scss';
@import '../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.logoArea {
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 50px;
	margin-top: 30px;
	padding-top: 40px;
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	.logoImg {
		@include setWidthHeight(100%, 100%);
		object-fit: contain;
	}
}
.menuItems {
	text-align: left;
	width: 100%;
	@include displayFlex(column, flex-start, center);
}
.navigateHeaderText {
	color: var(--footerHead);
	font-size: 18px;
	font-family: 'Arapey';
	font-weight: 400;
	font-style: normal;
	text-transform: uppercase;
	margin-bottom: 20px;
	text-align: center;
}

.navigateHeaderMessage {
	color: var(--footer1);
	font-size: 15px;
	font-family: 'Futura PT';
	font-weight: 400;
	cursor: pointer;
	display: block;
	text-align: center;
	padding: 6px 0;
	text-transform: capitalize;
}
.contactNum {
	color: var(--footer1);
	font-size: 15px;
	font-family: 'Futura PT';
	font-weight: 400;
	display: block;
	text-align: center;
	cursor: pointer;
	padding: 0px 0;
	text-decoration: none;
	&:last-of-type {
		margin-bottom: 15px;
	}
}
.socialLinkBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 50px;
	padding-bottom: 40px;
}

.socialLinkBox1 {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-around !important;
}

.allRightsText {
	font-family: 'Futura PT';
	font-size: 14px;
	color: var(--footer1);
	text-decoration: none;
}
.socialLinkTexts {
	color: #9c928c;
	font-size: 12px;
	font-family: 'Gotham-Book';
	font-weight: 400;
	cursor: pointer;
	text-decoration: none;
}
.socialLinkTexts:hover {
	color: #9c928c;
}

.onHover:hover {
	text-decoration: none;
	color: #ca9275;
}
.textdecor {
	text-decoration: none;
	color: var(--footer1);
	font-size: 15px;
	font-family: 'Futura PT';
	font-weight: 400;
	display: block;
	text-align: center;
	cursor: pointer;
	padding: 6px 0;
}
.backImage {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	filter: brightness(0) invert(1);
	@include setWidthHeight(400px, 100%);
	.backImg {
		@include setWidthHeight(400px, 100%);
	}
}
.leftFlower {
	position: absolute;
	bottom: 0;
	left: 0;
	max-width: 400px;

	@media (max-width: 768px) {
		display: none;
	}
}
.leftFlower img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.rightFlower {
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 340px;
	@media (max-width: 768px) {
		display: none;
	}
}
.rightFlower img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

/* new css  */

.spanTexts {
	color: var(--footer1);
	font-size: 15px;
	font-family: 'Futura PT';
	font-weight: 400;
	cursor: pointer;
	// display: block;
	text-align: center;
}

.website {
	color: var(--footer1);
	font-size: 15px;
	font-family: 'Futura PT';
	font-weight: 400;
	display: block;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	padding-bottom: 10px;
}
.alrightBox {
	display: block;
}
.zIndex {
	z-index: 10;
}
// .desktopView {
// 	@media only screen and (min-width: 1025px) {
// 		@include displayFlex(row, center, center);
// 	}
// 	@media only screen and (max-width: 1024px) {
// 		display: none;
// 	}
// }
.mobileView {
	@media only screen and (min-width: 1025px) {
		display: none !important;
	}

	@media only screen and (max-width: 1024px) {
		@include displayFlex(row, center, center);
	}
}
.divider {
	@media only screen and (min-width: 1025px) {
		display: none !important;
	}

	@media only screen and (max-width: 1024px) {
		width: 1px;
		height: 15px;
		background-color: #9c928c;
		margin-top: 6px;
		margin-left: 12px;
		margin-right: 12px;
	}
}
.linkItem {
	text-decoration: none;
}

@media only screen and (max-width: 1024) {
	.alrightBox {
		display: none;
	}
	.socialLinkBox1 {
		width: 100%;
	}
	.divider {
		width: 1px;
		height: 15px;
		background-color: #9c928c;
		margin-top: 6px;
		margin-left: 12px;
		margin-right: 12px;
	}
}

.newsLetterForm {
	width: 100%;
	@include displayFlex(row, center, center);
	@media (min-width: 1025px) {
		width: 85%;
		margin-right: 0;
		margin-left: auto;
	}
	.field {
		width: 100%;
		@media (max-width: 767px) {
			width: 60%;
		}
		.control {
			width: 100%;
			.newsLetterInput {
				margin-right: 0;
				padding: 0;
				border: none;
				background: 0 0;
				border: 1px solid #ffffff;
				max-width: 75%;
				margin: 20px auto 0px;
				display: block;
				text-align: center;
				color: #ffffff;
				border-radius: 1px;
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-size: 14px;
				height: 32px;
				line-height: 1.42857143;
				width: 100%;
				box-sizing: border-box;
				&:focus,
				&:active,
				&:hover {
					outline: none;
				}
				&::placeholder {
					color: #ffffff;
				}
			}
		}
	}
	.subscribeBtn {
		background: transparent;
		border-radius: 0;
		border: none;
		text-transform: uppercase;
		font-size: 35px;
		font-family: 'Cormorant Garamond';
		font-weight: 400;
		letter-spacing: 1px;
		color: white;
		@include setWidthHeight(0px, 0px);
		margin: 10px 0 0;
	}
	.response {
		font-family: 'Futura PT';
		font-size: 14px;
		&.error {
			color: rgb(255, 0, 0);
		}
		&.success {
			color: rgb(0, 128, 0);
		}
	}
}

