@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../../theme/utils/Mixins.module.scss';
@import '../../../theme/utils/Variables.module.scss';
@import '../../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.ForgetPassword{
    background: url(https://janaviindia.com/static/frontend/Digital/default/en_US/images/loginSignupBanner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    @include displayFlex(row, center, flex-start);
    .myContainer{
        @media only screen and (max-width:767px){
            padding: 0;
        }
        .formPage{
            max-width: 800px;
            padding: 30px;
            background: rgba(0,0,0,.6);
            border: 1px solid var(--primary2);
            color: #fff;
            text-align: center;
            margin: 100px auto;
            .customerLoginBlockInner{
                max-width: 600px;
                margin: 3rem auto 0px;
                padding: 0 20px;
                .blockTitleContainer{
                    .blockTitle{
                        text-transform: initial;
                        font-size: 22px;
                        font-family: "Cormorant Garamond";
                        font-weight: 500;
                        text-align: center;
                        display: block;
                        // letter-spacing: 1px;
                        margin-bottom: 30px;
                        color: #fff;
                    }
                }
                .blockContentContainer{
                    margin: 0;
                    .errorMsg{
                        font-family: "Futura PT";
                        color: red;
                        font-size: 13px;
                        text-align: left;
                    }
                    .myForm{
                        .myFormFields{
                            margin: 5px 0;
                            width: 100%;
                        }
                        .myInputField{
                            width: 100%;
                            background: 0 0;
                            height: 40px;
                            border-radius: 0;
                            appearance: none;
                            border: 1px solid var(--primary2);
                            text-transform: lowercase;
                            font-family: "Futura PT";
                            font-weight: 400;
                            color: #fff;
                            padding: 0 9px;
                            font-size: 14px;
                            &::placeholder{
                                color: var(--secondary6);
                                font-size: 14px;
                                text-transform: capitalize;
                                font-family: "Futura PT";
                                font-weight: 300;
                            }
                            &:focus{
                                box-shadow: 0 0 3px 1px #68a8e0;
                                outline: none;
                            }
                        }
                        .captchaContainer{
                            .captchaContainerContent{
                                margin: 10px auto;
                                text-align: left;
                                .captchaImg{
                                    margin: 0 10px 0 0;
                                    vertical-align: middle;
                                    height: 50px;
                                    width: auto;
                                    @media only screen and (max-width:767px){
                                        margin-bottom: 10px;
                                        display: block;
                                    }
                                }
                                .reloadBtn{
                                    vertical-align: bottom;
                                    font-size: 13px;
                                    font-family: "Roboto Mono";
                                    font-weight: 300;
                                    text-transform: uppercase;
                                    color: var(--primary1);
                                    background-image: none;
                                    background: #f2f2f2;
                                    border: 1px solid #cdcdcd;
                                    margin: 0;
                                    padding: 7px 15px;
                                    cursor: pointer;
                                    display: inline-block;
                                    line-height: 1.6rem;
                                    box-sizing: border-box;
                                }
                            }
                        }
                        .actionButtons{
                            .secondary{
                                width: 100%;
                                color: #fff;
                                .forgotPwd{
                                    text-decoration: none;
                                    margin: 0;
                                    font-family: "Futura PT";
                                    font-weight: 300;
                                    color: #fff;
                                    font-size: 11px;
                                    text-transform: lowercase;
                                    display: block;
                                    text-align: right;
                                    &:hover{
                                        text-decoration: underline;
                                    }
                                }
                            }
                            .primary{
                                margin-bottom: 0;
                                width: 100%;
                                
                            }
                        }
                    }
                    
                }
            }
            
        }
    }
}


