@mixin displayFlex($direction, $justifyContent, $alignItems) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justifyContent;
    align-items: $alignItems;
}

@mixin setWidthHeight($width, $height) {
    width: $width;
    height: $height;
}

@mixin noOutlineOnAction {
    &:focus, &:active, &:hover{
        outline: none;
        box-shadow: none;
    }
}