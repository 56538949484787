@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '../../../theme/utils/Mixins.module.scss';
@import '../../../theme/utils/Variables.module.scss';
@import '../../../theme/fonts/font.css';

.accordion-button:not(.collapsed)::after {
	transform: rotate(42deg) !important;
}

.swiper-product {
	&.swiper-slide-active {
		@media (min-width: 1025px) {
			margin-right: 195px !important;
		}
	}
}
.stickySidebar {
	@media (min-width: 768px) {
		flex-grow: 1;
		flex-basis: 300px;
		align-self: start;
		position: sticky;
		top: 0;
	}
}
// {
// 	margin-right: 30px !important;
// 	&
// }

.activeSwiper {
	margin-right: 195px !important;
}
// Auth pages
.signIn {
	margin-top: 10px;
	margin-bottom: 0;
	background: var(--primary2);
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Cormorant Garamond';
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	width: 100%;
	height: 40px;
}

// General
body {
	background-color: #f5f4f2;
}
.sectionHead {
	text-align: center;
	font-family: 'Cormorant Garamond';
	font-weight: lighter;
	font-size: 40px;
	color: var(--primary4);
	text-transform: uppercase;
	margin-bottom: 30px;
}

.myContainer {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
}
.myMobContainer {
	@media (max-width: 1024px) {
		padding: 0 5px;
	}
}

.customLink {
	text-decoration: none;
	color: var(--primary2);
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}
.navLink {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		color: var(--primary2);
	}
}

.formOverlay {
	display: block;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.hideOnWeb {
	@media (min-width: 768px) {
		// display: none;
	}
}
.showOnMob {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

@media (min-width: 1025px) {
	.d-web-none {
		display: none !important;
	}
	.d-web-block {
		display: block !important;
	}
}

.newsLetterSection{
    background: var(--secondary1);
    max-width: 100%;
    width: 100%;
    padding: 50px 0;

    .myContainer{
        .content{
            margin: 0px auto;
            max-width: 50%;
            width: 50%;
            @media (max-width:767px){
                width: 100%;
                max-width: 100%;
            }
            .sectionHead{
                margin-bottom: 0;
                @media only screen and (max-width: 767px){
                    font-size: 30px;
                }
            }
            .subHead{
                font-size: 17px;
                color: var(--secondary2);
                font-family: "Futura PT";
                text-align: center;
                margin: 15px 0;
            }
            .newsLetterForm{
                width: 100%;
                @include displayFlex(column, center, center);
                .field{
                    width: 100%;
                    .control{
                        width: 100%;
                        .newsLetterInput{
                            margin-right: 0;
                            padding: 0;
                            border: none;
                            background: 0 0;
                            border-bottom: 1px solid var(--secondary3);
                            max-width: 60%;
                            margin: 20px auto 10px;
                            display: block;
                            text-align: center;
                
                            border-radius: 1px;
                            font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                            font-size: 14px;
                            height: 32px;
                            line-height: 1.42857143;
                            width: 100%;
                            box-sizing: border-box;
                            &:focus, &:active, &:hover{
                                outline: none;
                            }
                        }
                    }
                }
                .subscribeBtn{
                    background: var(--primary2);
                    border-radius: 0;
                    border: none;
                    text-transform: uppercase;
                    font-size: 13px;
                    font-family: "Cormorant Garamond";
                    font-weight: 400;
                    letter-spacing: 1px;
                    color: white;
                    @include setWidthHeight(180px, 46px);
                    margin-top: 20px;
                }
                .response{
                    font-family: "Futura PT";
                    font-size: 14px;
                    &.error{
                        color: rgb(255, 0, 0);
                    }
                    &.success{
                        color: rgb(0, 128, 0);
                    }
                }
            }
        }
    }
}