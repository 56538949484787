:root {
	// Colors
	--primary1: #1f1f1f;
	--primary2: #ca9275;
	--primary3: #1f1f1f;
	--primary4: #4d3535;

	--secondary1: #f6f4f2;
	--secondary2: #211616;
	--secondary3: #333333;
	--secondary4: #f5f4f2;
	--secondary5: #ca9274;
	--secondary6: #c2c2c2;

	--footerHead: #9a908a;
	--footer1: #e5dfd9;

	--small-screen: 767px;
	--mid-screen: 1024px;
}
